define('main/notifications',[
    'app',
    'backbone',
    'main/lib',
    'main/settings',
    'main/UI'
], function (App, Backbone, Lib, Settings, UI) {

    var Notifications = Backbone.Model.extend({
        initialize: function () {
            var self = this;
            this.set({supported: !!window.Notification});

            function setStatus (settings_value) {
                if (self.get("supported") && settings_value === undefined
                        && Notification.permission === "granted") {
                    self.set({granted: true});
                } else if (settings_value === true && self.get("supported")) {
                    Notification.requestPermission(function(result) {
                        if (result === "granted") {
                            self.set({granted: true});
                            Settings.set({'desktop-notifications': true});
                            Settings.set({'zapier-pop-up': true});
                        } else {
                            self.set({granted: false});
                            Settings.set({'desktop-notifications': false});
                            Settings.set({'zapier-pop-up': false});

                            App.layout.regionIntro.show(new App.UI.Views.TaskModal({
                                callback: function (self) {
                                    var win = window.open('https://hub.securevideo.com/Knowledge/Details/63', '_blank');
                                    win.focus();

                                    // close the modal
                                    self.$el.find('[data-dismiss="modal"]').click();
                                },
                                headline: 'Notifications disabled by user.',
                                info_text: 'Click \'ok\' to see how to enable them!',
                                ok_text: ' ok',
                                cancel_text: 'cancel'
                            }));
                        }
                    });
                } else {
                    self.set({granted: false});
                }
            }

            Settings.on("change:desktop-notifications", function (model, value, options) {
                setStatus(value);
            });
            setStatus(Settings.get('desktop-notifications'));
            
            Settings.on("change:zapier-pop-up", function (model, value, options) {
                setStatus(value);
            });
            setStatus(Settings.get('zapier-pop-up'));

            if ('serviceWorker' in navigator && 'permissions' in navigator ) {  
                navigator.serviceWorker.register('/service.js');


                navigator.permissions.query({name: 'push', userVisibleOnly: true}).then(function (permissionState) {
                    // Check what the current push state is
                    return navigator.serviceWorker.ready;
                }.bind(this)).then(function (serviceWorkerRegistration) {
                    // Let's see if we have a subscription already
                    self.serviceWorkerRegistration = serviceWorkerRegistration;

                    function tabs_open_channel_handler (event) {
                        if (event.data && event.data > 1) {
                            // the App.layout is not available at this point.
                            // It's better to user the classic window.alert to display the message
                            window.alert('Voxer may be open in another tab or you may be using an unsupported browser. Please close this tab and/or ensure you are using a Chrome browser.')
                            window.open('/assets/html/not-allowed.html','_self').close()
                            // var modal = new UI.Views.TaskModal({
                            //     callback: function (self, event) {
                            //         window.open("about:blank", "_self").close();
                            //     },
                            //     headline: 'Another tab opened!',
                            //     info_text: 'Voxer is already open in another tab.',
                            //     ok_text: 'Close',
                            //     cancel_text: null
                            // });
                            // if (App.layout && App.layout.regionIntro){
                            //     App.layout.regionIntro.show(modal)
                            // }
                        }
                    }
                    if (serviceWorkerRegistration.active) {
                        serviceWorkerRegistration.active.postMessage({command: 'block_incoming'});
                        var messageChannel = new MessageChannel();
                            messageChannel.port1.onmessage = tabs_open_channel_handler;
                        serviceWorkerRegistration.active.postMessage({command: 'tabs_open'}, [messageChannel.port2]);
                    } else {
                        serviceWorkerRegistration.addEventListener('activate', function serviceWorkerRegistration_activate() {
                            serviceWorkerRegistration.removeEventListener('activate', serviceWorkerRegistration_activate);
                            serviceWorkerRegistration.active.postMessage({command: 'block_incoming'});
                            var messageChannel = new MessageChannel();
                                messageChannel.port1.onmessage = tabs_open_channel_handler;
                            serviceWorkerRegistration.active.postMessage({command: 'tabs_open'}, [messageChannel.port2]);
                        }.bind(this));
                    }

                    return serviceWorkerRegistration.pushManager.getSubscription();
                }).then(function (subscription) {
                    if (subscription) {
                        return subscription;
                    } else {
                        return self.serviceWorkerRegistration.pushManager.subscribe({userVisibleOnly: true});
                    }
                }).then(function (subscription) {
                    var old_subscription = Settings.fetch('old_subscription'),
                        gcm_token,
                        client_name;

                    if (subscription.endpoint.indexOf('https://android.googleapis.com/gcm/send/') === 0) {
                        gcm_token = subscription.endpoint.split('https://android.googleapis.com/gcm/send/')[1];
                        client_name = "voxer";
                    } else if (subscription.endpoint.indexOf('https://updates.push.services.mozilla.com/push/') === 0) {
                        gcm_token = subscription.endpoint.split('https://updates.push.services.mozilla.com/push/')[1];
                        client_name = "voxer_firefox";
                    } else {
                        return;
                    }

                    if (old_subscription != gcm_token) {
                        old_subscription = gcm_token;
                        Settings.set({ old_subscription: old_subscription });
                        Settings.set({ client_name: client_name });
                    }

                    // Add subscription to object so we can unsubscribe
                    self.subscription = subscription;

                    if (self.serviceWorkerRegistration.active) {
                        self.serviceWorkerRegistration.active.postMessage({
                            command: 'set_url',
                            url: location.origin || "https://web.voxer.com/"
                        });
                    }
                }).catch(function (err) {
                    // No error if user denied
                    if (err && err.message && err.message.indexOf('permission denied') === -1){
                        console.error('PushClient.setUpPushPermission() Error', err.message);
                    }
                });

                App.vent.on('update_service', function () {
                    if (self.serviceWorkerRegistration && self.serviceWorkerRegistration.update) {
                        App.API._post_message({
                            device_token: Settings.fetch('old_subscription')
                        }, {
                            url: 'notifications/disable_device_token/1',
                            asynchronousRequest: false,
                            home_router: App.Settings.get('home_router')
                        });
                        self.serviceWorkerRegistration.update();
                    }
                }.bind(this));
            } else {  
                console.log('Service workers aren\'t supported in this browser.');  
            }
            window.addEventListener("beforeunload", function () {

                if (Settings.fetch('desktop-notifications') && Settings.fetch('old_subscription')) {
                    if (self.serviceWorkerRegistration && self.serviceWorkerRegistration.active) {
                        self.serviceWorkerRegistration.active.postMessage({command: 'unblock_incoming'});
                    }

                    self.register_device();
                } else if (!Settings.fetch('desktop-notifications') && Settings.fetch('old_subscription')) {
                    App.API._post_message({
                        device_token: Settings.fetch('old_subscription')
                    }, {
                        url: 'notifications/disable_device_token/1',
                        asynchronousRequest: false,
                        home_router: App.Settings.get('home_router')
                    });
                }
            });
        },
        register_device: function () {
            App.API._get_message({
                uuid: Lib.getBrowserFingerprint(App.Auth.get('rebelvox_user_id')),
                gcm_token: Settings.fetch('old_subscription'),
                client_name: Settings.fetch('client_name') || 'voxer'
            }, {
                home_router: Settings.fetch('home_router'),
                url: '/2/cs/register_c2dm_token'
            });
        },
        show_notification: function (message) {
            if (!this.get('granted') || !this.get('supported')){
                return
            }
            if (message.get('from') !== App.Auth.get('rebelvox_user_id') &&
                message.get('append') === true &&
                document.hidden) {
                // Now we show the notification
                var avatar_src,
                    message_body,
                    profile = App.profiles.get(message.get('from'));
                avatar_src = profile ? profile.avatar_src() : "/assets/img/person.png";
                if (message.get('content_type') === 'audio') {
                    message_body = "sent you an audio message";
                } else {
                    message_body = App.chat.Model.prototype.prepare_last_vox_snippet(message.attributes);
                    message_body = html2text(message_body);
                }

                var title = (message.get('thread_id').indexOf('HL_') === 0) ?
                            message.get('sender_name') :
                            message.get('chat_name') + ': \n' + message.get('sender_name');

                var n = new Notification(title, {
                    icon: avatar_src,
                    body: message_body
                });
                n.onclick = function () {
                    window.focus();
                    App.chats.forEach(function(chat){
                        if (chat.get('last_message').message_id === message.get('message_id'))
                            $('#' + chat.cid).click();
                    });
                }
                _.delay(n.close.bind(n), 5000);
            }

            function html2text(html) {
                var tag = document.createElement('div');
                tag.innerHTML = html;
                return tag.innerText;
            }
        }
    });

    return new Notifications();
});
