define('main/integrations',[
  "app",
  // Libraries.
  "backbone",
  "main/UI",
  "main/settings",
], function (App, Backbone, UI, Settings) {
  var M = Backbone.Marionette;
  var integrations = new App.module();
  var selectedIntegration;

  integrations.Views.IntegrationView = M.ItemView.extend({
    template: "integrations/main",
    events: {
      "click #plangrid-div": "openPlangridModal",
      "click #procore-div": "openProcoreModal",
      "click #zapier-div": "openZapierModal",
      "click #save-button": "saveZapierIntegrationSettings",
      "click #upgrade-button": "handleUpgrade",
    },
    onRender: function () {
      App.vent.trigger("metrics:track", "/integrations");
    },
    initialize: function () {},
    serializeData: function () {
      var capabilities = App.Auth.get("capabilities");
      var business_user = App.Auth.get('business_id') != undefined;
      var zapier_settings = Settings.get("user_settings") && Settings.get("user_settings").zapier;
      var zapier_webhook = "";
      var zapier_toggle_all = false;
      if (zapier_settings && zapier_settings.webHookLink) {
        zapier_webhook = zapier_settings.webHookLink[0]
          ? zapier_settings.webHookLink[0]
          : "";
        // zapier_toggle_all = zapier_settings.toggle_for_all;
      }


      return {
        is_pro: !business_user && capabilities && Object.keys(capabilities.capability_keys).length,
        business_user: business_user,
        saved_webhook: zapier_webhook,
        zapier_toggle_all: zapier_toggle_all,
        integrationName: selectedIntegration,
        modalDescriptionPt1:
          "Integrating syncs your contacts from your construction software with Voxer and creates group chats based on your teams.",
        modalDescriptionPt2:
          "If your contact is not on Voxer, this person will receive an email invite asking them to join your Voxer team chats.",
      };
    },
    handleUpgrade: function () {
      App.Router.navigate("upgrade", { trigger: true });
    },
    saveZapierIntegrationSettings: function (e) {
      var webHookLinkInput = $("#webHookLink").val();
      var toggleForAll = $('input[name="toggle"]:checked').val() === "true";
      const urlRegex = /^https:\/\/([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(:[0-9]{1,5})?(\/.*)?$/;
      // Change: Accept empty URL or validate if not empty
      if (webHookLinkInput.trim() === "" || urlRegex.test(webHookLinkInput)) {
        App.API.add_web_hook_zapier(
          {
            changes: {
              add: {
                zapier: {
                  webHookLink: [webHookLinkInput],
                  toggle_for_all: toggleForAll,
                },
              },
            },
          },
          {
            home_router: App.Settings.fetch("home_router"),
          },
          function (e) {
            console.error(e);
          },
          function (response) {
            if (response && response.user_id) {
              $("#success_message_zapier")
                .addClass("display-message")
                .removeClass("display-error")
                .removeClass("hide-message");
              $("#error_message_zapier").addClass("hide-message");
              App.Settings.set({ user_settings: response });
            } else {
              $("#error_message_zapier")
                .addClass("display-error")
                .removeClass("display-message")
                .removeClass("hide-message");
              $("#success_message_zapier").addClass("hide-message");
            }
          }
        );
      } else {
        $("#error_message_zapier")
          .addClass("display-error")
          .removeClass("display-message")
          .removeClass("hide-message");
        $("#success_message_zapier").addClass("hide-message");
      }
    },
  });
  return integrations;
});

