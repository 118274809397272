/*
 * The router defines routes and their corresponding methods in the controller.
 */
/*jslint browser: true*/
define('main/setup',[
    "app",
    // Libraries.
    'backbone',
    // Modules.
    'main/lib',
    'plugins/favico',
    'plugins/sound'
], function (App, Backbone, Lib, Favico) {
    'use strict';

    var Setup = {};

    Setup.init = function (App) {
        if (App.Config !== undefined) {
            App.origin_router = App.Config.get('router');
            App.Settings.set({config: App.Config.attributes});
            if (! App.Settings.fetch('home_router')) {
                App.Settings.set({home_router: App.home_router});
            }
        } else {
            this._home_router().then(function (value) {
                var origin_router = JSON.parse(value);
                App.origin_router = origin_router.router;
                App.Settings.set({config: origin_router});
                if (! App.Settings.fetch('home_router')) {
                    App.Settings.set({home_router: App.home_router});
                }
            });
        }

        // the webserver does not change
        App.webserver = this._webserver(App);
        App.vbm = App.Config.get('vbm_url');

        console.log("SETUP: original home_router: " + App.origin_router + " --- web_server: " + App.webserver);
        if (detectBrowser().indexOf('msie') === -1) {
            App.favicon = new Favico({
                animation: 'pop',
                bgColor : '#47C76B',
                textColor : '#ffffff'
            });
        }

        App.VoxerAudio.Init(this._webserver(true));

        App.create_url = function (uri, query_params) {
            var options = _.isEmpty(App.Settings.attributes) || _.isEmpty(App.Settings.attributes.home_router) ? App.Auth.attributes : App.Settings.attributes;
            if (!options.home_router) {
                options.home_router = App.home_router;
            }
            return Lib.create_url(uri, query_params, options);
        };
        var id;
        $(window).resize(function () {
            clearTimeout(id);
            id = setTimeout(Setup._after_resize, 500);
        });

        if (!window.location.origin) {
            window.location.origin = window.location.protocol + "//" + window.location.host;
        }

        console.log("SETUP: initialize soundmanager, loading sounds");

        soundManager.setup({
            waitForWindowLoad: false,
            url: '/assets/bower/soundmanager2/swf',
            flashLoadTimeout: 3000,
            flashVersion: 9,
            useVoxerAudioBlock: false,
            debugMode : false,
            preferVoxerAudio: false,

            onready: function () {
                // Ready to use; soundManager.createSound() etc. can now be called.
                console.log('SM is ready');
                App.sounds.mySound = soundManager.createSound({
                    id: 'knock',
                    url: '/assets/sounds/click-high.mp3',
                    onload: function () {
                        //console.log('sound loaded!', this);
                    }
                    // other options here..
                });
                App.sounds.chirp = soundManager.createSound({
                    id: 'chirp',
                    url: '/assets/sounds/d.mp3',
                    onload: function () {
                        //console.log('chirp loaded!');
                    }
                });
                App.sounds.chirp_2 = soundManager.createSound({
                    id: 'chirp_2',
                    url: '/assets/sounds/d2.mp3',
                    onload: function () {
                        //console.log('chirp loaded!');
                    }
                });
                App.sounds.chirp_4 = soundManager.createSound({
                    id: 'chirp_4',
                    url: '/assets/sounds/d4.mp3',
                    onload: function () {
                        //console.log('chirp loaded!');
                    }
                });
                App.sounds.chirp_6 = soundManager.createSound({
                    id: 'chirp_6',
                    url: '/assets/sounds/d6.mp3',
                    onload: function () {
                        //console.log('chirp loaded!');
                    }
                });
                App.sounds['walkie'] = soundManager.createSound({
                    id: 'walkie',
                    url: '/assets/sounds/walkie.mp3',
                    onload: function () {
                        //console.log('walkie loaded!');
                    }
                });
            },
            ontimeout: function () {
                console.log("SM: TIME OUT");
                setTimeout(soundManager.reboot, 20);
            }
        });

        // setup the un-wrapping of ajax call responses which are nicely wrapped for IE9's sake
        // thanks IE9!
        this.initAjax();

    };

    Setup._home_router = function () {
        var url = Setup._webserver(true),
            local_config = localStorage['config'];

        function remote_config () {
            return $.ajax({
                method: 'GET',
                dataType: 'text',
                url: '/config',
                async: true,
                xhrFields: {
                    withCredentials: false
                }
            });
        }

        if (!!local_config && local_config.expires && (local_config.expires > Date.now())) {
            if (JSON.parse(local_config).version === voxer.version &&
                    typeof voxer.version === "string") {
                return new $.Deferred().resolve(local_config);
            } else if (typeof voxer.version !== "string") {
                return new $.Deferred().resolve(local_config);
            } else {
                var saved = {
                    'login-id': window.App.Settings.fetch('login-id'),
                    'hash': window.App.Settings.fetch('hash'),
                    'sso_token': window.App.Settings.fetch('sso_token'),
                    'accessToken': window.App.Settings.fetch('accessToken'),
                    'users_routes': window.App.Settings.fetch('users_routes'),
                    'home_router': window.App.Settings.fetch('home_router'),

                    // User settings
                    'cache-timeline': window.App.Settings.fetch('cache-timeline'),
                    'desktop-notifications': window.App.Settings.fetch('desktop-notifications'),
                    'zapier-pop-up': window.App.Settings.fetch('zapier-pop-up'),
                    'location': window.App.Settings.fetch('location'),
                    'sound-on': window.App.Settings.fetch('sound-on'),
                    'sound-level': window.App.Settings.fetch('sound-level'),
                    'sort-by': window.App.Settings.fetch('sort-by'),
                    'talk-method': window.App.Settings.fetch('talk-method')
                }

                localStorage.clear();
                window.App.vent.trigger('update_service');
                window.App.Settings.save(saved);

                return remote_config();
            }
        } else {
            return remote_config();
        }

    };

    Setup._after_resize = function () {
        console.log('resizing');
        // resize all remaining chat panels in the workpsace
        var workspace_width = $('.workspace').width() - 1;

        //20 pixels is subtracted to make up for borders and other whatnaught
        if (workspace_width !== -1) {
            window.App.video_width = parseInt($('.chat-panel').css('width')) * 0.666 - 100;
            window.App.panel_height = ($('.chat-panel .panel-body').height() - 100);
            _.each(videos, function (video) {
                var height = video.height();
                var width  = video.width();
                var ratio  = parseFloat((((width > height) ? width : height) / window.App.video_width).toFixed(2));

                video.height(parseInt(height / ratio));
                video.width(parseInt(width / ratio));
            }.bind(this));
        }
    };

    Setup._vbm = function (webserver) {
        return webserver.replace('web', 'business').replace('10443', '10444');
    };

    Setup._webserver = function (uri) {
        var location = _get_document_location(),
            hostparam,
            backend,
            full_url = get_parameter_by_name('full_url'),
            host = get_parameter_by_name('host'),
            state = get_parameter_by_name('state');

        if (Setup.MyWebserver !== undefined) {
            if (uri) return Setup.MyWebserver
            return 'https://' + Setup.MyWebserver;
        }

        if (state !== undefined && state !== '') {
            Setup.MyWebserver = state + '.voxer.com';
            if (uri) return state + '.voxer.com';
            return 'https://' + state + '.voxer.com';
        }


        if (full_url !== undefined && full_url !== '') {
            Setup.MyWebserver = full_url;
            return get_parameter_by_name('full_url');
        }


        if (host!== undefined && host !== '') {
            hostparam = host;
            if (hostparam === 'one') {
                backend = 'web.voxer.com';
            }

            Setup.MyWebserver = hostparam + '.voxer.com';
            if (uri) return hostparam + '.voxer.com';
            return 'https://' + hostparam + '.voxer.com';
        }

        Setup.MyWebserver = location.hostname;
        if (uri) return location.hostname;
        return 'https://' + location.hostname;
    };

    Setup._subdomain = function () {
                var location = _get_document_location(),
            hostparam,
            backend,
            full_url = get_parameter_by_name('full_url'),
            host = get_parameter_by_name('host'),
            state = get_parameter_by_name('state');

        if (state !== undefined && state !== '') {
            if (state.indexOf('host') === 0) {
                var hostparam = state.slice(5, state.length);
                return hostparam;
            }
        }

        if (full_url !== undefined && full_url !== '') {
            return get_parameter_by_name('full_url');
        }

        if (host!== undefined && host !== '') {
            hostparam = host;
            if (hostparam === 'one') {
                backend = 'web';
            }
            return hostparam;
        }

        var full = window.location.host;
        var parts = full.split('.');
        var sub = parts[0];

        return sub;
    }

    Setup._is_dev = function () {
        var location = _get_document_location();
        return location.hostname.indexOf('web.voxer.com') === -1 && location.hostname.indexOf('web.vxrllc.com') === -1;
    };

    Setup.initAjax = function () {
        //Rewriting all ajax calls to handle our json CRLF

        console.log('SETUP: AJAX configured');
    };

    Setup.console = function () {


        if (!Setup._is_dev()) {
            var noop = function () {};
            window.console = {};
            window.console.log = noop;
            window.console.warn = noop;

            return false;
        }
        // prepend timestamp to logs
        /*
        var old = console.log;
        console.log = function () {
            var isChrome, stack, file, line, append;
            Array.prototype.unshift.call(arguments, new Date().getTime() + ': ');
            //old.apply(this, arguments)

            isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
            if (isChrome) {
                stack = new Error().stack;
                stack = stack.split("\n")[2].split(":");
                file = stack[stack.length - 3].split("/");
                file = file[file.length - 1];
                line = stack[stack.length - 2];
                append = '[' + file + ":" + line + ']';
            }
            if (this._is_dev()) {
                Array.prototype.push.call(arguments, append);
                old.apply(this, arguments);
            }
        };
        */
    };

    return Setup;
});

