/*jslint browser: true*/
define('app',[

  // Libraries.
    'jquery',
    'underscore',
    'backbone',
    'main/setup',
    'main/track',
    'main/settings',

    'constants',

    'backbone.marionette',
    'bootstrap',

    // plugins
    'plugins/voxer',
    'plugins/multiregion',
    'plugins/jquery.xdomain',
    'routefilter'
], function ($, _, Backbone, Setup, Metrics, Settings) {
    'use strict';

    var Marionette = Backbone.Marionette;

    console.log("APP: entering app");
    // Creates a new Marionette application.
    var App = new Marionette.Application();

    Marionette.Region.prototype.open = function (view) {
        this.$el.html(view.el);
    };

    App.voxer = {
        debug: true,
        biz: false,
        system_name: 'web_browser',
        dev: document.location.hostname.indexOf('web.voxer.com') === -1 &&
            document.location.hostname.indexOf('web.vxrllc.com') === -1,
        is_vxr: document.location.hostname.indexOf('vxrllc.com') !== -1,
        SESSION_TO: voxer && voxer.is_vxr ? 3600 : null

    };

    App.staredMessages = [];
    window.videos = [];

    App.Settings = Settings;
    // Set up basic paths.
    App.root = '/';
    App.sounds = [];

    // Add the main region, that will hold the page layout.
    App.addRegions({
        regionMain: '#main'
    });

    // before initializing the app with home_router (ping)
    // chat_list and profiles
    App.on("before:start", function () {
        console.log("APP: before:start");
        Setup.init(this);
        this.initAppLayout();

        if (get_location !== undefined) {
            this.set_location();
        } else {
            App.on("before:start", function () {
                this.set_location();
            });
        }

        // TODO check in settings/cache if we have a home router already and use that one
        var cached_home_router = Settings.fetch('home_router');
        if (!cached_home_router || cached_home_router !== App.Settings.fetch('home_router')) {
            App.home_router = "https://" + this.home_router;
            App.Settings.set({home_router: App.home_router});

            // TODO: remove
            $('#topbar').append(" Talking to: " + App.home_router);
        }
        else {
            App.home_router = cached_home_router;
            App.webserver = Setup._webserver(App);
        }
    });

    App.vent.on("login", function (cred, $button, $message) {
        App.Auth.login(cred).then(function (response) {
            App.Auth.set(response);
            if (response.reset_pass_on_login) {
                App.Router.navigate('/change_password', {
                    trigger: true
                });
                return true;
            }
            // TODO: trigger a global event on login (if needed)
            App.vent.trigger('session:new', response);
            App.vent.trigger('metrics:identify', response.rebelvox_user_id);
            App.vent.trigger('metrics:register', {
                voxer_client: 'v4w',
                voxer_version: voxer.version,
                business_id: response.business_id,
                distinct_id: response.user_id
            });
            App.vent.trigger('metrics:track', 'v4w/log_in');

            response.user_id = response.rebelvox_user_id;
            App.Auth.set(response);
            // console.log("session set:", App.Auth.attributes);
            App.home_router = "https://" + response.home_router.address + ":" + response.home_router.port;
            Settings.set({
                'home_router': App.home_router,
                'port': response.home_router.port,

                'login-id': App.Auth.get('login_id'),
                'user_id': App.Auth.get('user_id'),
                'logged-in': true,
                'logged-since': new Date().getTime()
            });

            App.render_layout();

        }).fail(function (error, response) {
            // update the UI
            if ($button !== undefined) {
                $button.button('reset');
                if (error.error === "The web client requires Voxer Pro credentials.") {
                    App.layout.regionContent.show(new Account.Views.FreeTrial({
                        voxer: {},
                        router: 'abc'
                    }));
                }


                $message.html(error.error || "There was an error, please try again.").removeClass('hide');
            }
        });

    });

    App.render_layout = function () {
        // render the layout
        App.splitBodyLayout = new App.UI.Layouts.SplitBody();
        App.layout.regionContent.show(App.splitBodyLayout);
        App.layout.regionContent.currentView.workspace = new Backbone.Marionette.MultiRegion({
            el: '#content .workspace'
        });
        App.shoulderLayout = new App.UI.Layouts.Shoulder();
        App.layout.regionTopbar.show(App.shoulderLayout);

        $('#main').removeClass('no-header');
        $('.block').removeClass('login');

        var promise = App.request('load:allthings');

        //////////////////////////////////////////////////////////////////////////
        return App.Message.update_timeline().done(function () {
            // render My Profile pic
            App.layout.regionHeader.currentView.menuRegion.$el
            .find('.small-profile-pic')
            .attr('data-original', App.MyProfile.avatar_src())
            .lazyload({effect: "fadeIn"});

            // head over to the default view on login
            App.Router.navigate('/chats', {trigger: true, replace: true});
            App.vent.trigger('avatar_bust', true);
        });
    }

    App.reqres.setHandler('home_router', function () {
        var home_router = Settings.fetch('home_router');
        if (home_router) {
            return home_router;
        }
        if (App.Auth) {
            home_router = App.Auth.get('home_router');
            if (home_router) {
                return home_router;
            }
        }
        return Setup._home_router();
    });

    App.set_location = function () {
        var that = this;
        if (App.Settings.fetch('location')) {
            get_location({
                success: function (e) {
                    App.Settings.set({geo: e});
                },
                error: function (e) {
                    App.Settings.set({geo: undefined});
                }
            });
            if (this.interval === undefined) {
                this.interval = setInterval(function () {
                    console.log('Updating location');
                    get_location({
                        success: function (e) {
                            App.Settings.set({geo: e});
                        },
                        error: function (e) {
                            App.Settings.set({geo: undefined});
                        }
                    });
                }, 1000 * 60);
            }
        } else {
            clearInterval(this.interval);
            this.interval = undefined;
            App.Settings.delete('geo', true);
        }
    },

    App.profileImageUploadHandler = function (event, onPrepare, onComplete) {
        var file;

        // Only process image files.
        if (event && event.target && event.target.files[0].type.match('image.*')) {
            file = window.URL.createObjectURL(event.target.files[0])
        } else if (event && typeof event === "string") {
            file = event;
        } else {
            return;
        }

        var reader = new FileReader(),
            image = new Image(),
            image_width,
            image_height,
            user_id = App.Auth.get("rebelvox_user_id"),
            message_id = "profile_picture_" + user_id;

        // Closure to capture the file information.
        reader.onload = function(e) {
            var user_id = App.Auth.get("rebelvox_user_id"), now = +new Date() / 1000,
                message_id = "profile_picture_" + user_id;

            var meta = {
                session_key: App.Auth.get('Rv_session_key'),
                from: user_id,
                to: [user_id],
                message_id: message_id,
                content_type: 'image',
                content_json: {
                    dimensions: {
                        w: image_width,
                        h: image_height
                    }
                }
            }

            meta.secure_router = {
                host: App.Auth.attributes.home_router.address,
                port: App.Auth.attributes.home_router.port
            }

            if (App.VoxerAudio.socket_connected) {
                if (typeof onPrepare == "function")
                    onPrepare();
                var stream = App.VoxerAudio.client.send(e.target.result, meta);
                stream.on('data', function (data) {
                    if (typeof onComplete == "function")
                        onComplete(data);
                });

                stream.on('end', function (data) {
                    stream.destroy();
                });
            } else {
                App.VoxerAudio.connect_ws(App.webserver, function () {
                    App.profileImageUploadHandler(event, onPrepare, onComplete)
                })
            }
        };

        image.onload = function() {
            var size,
                sx = 0,
                sy = 0,
                canvas = document.createElement('canvas');

            if (this.width > this.height) {
              size = this.height;
              sx = (this.width - this.height) / 2;
            } else {
              size = this.width;
              sy = (this.height - this.width) / 2;
            }

            canvas.width = size;
            canvas.height = size;
            image_width = size;
            image_height = size;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(this, sx, sy, size, size, 0, 0, size, size);
            reader.readAsArrayBuffer(App.dataUriToBlob(canvas.toDataURL("image/png")));
        };

        image.setAttribute('crossOrigin', 'anonymous');
        image.src = file;
    };

    App.dataUriToBlob = function(dataURI) {
        // serialize the base64/URLEncoded data
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        }
        else {
            byteString = unescape(dataURI.split(',')[1]);
        }

        // parse the mime type
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // construct a Blob of the image data
        var array = [];
        for(var i = 0; i < byteString.length; i++) {
            array.push(byteString.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: mimeString});
    }
    // Start backbone's history for hash navigation after the app was initialized.
    /*App.on('start', function () {
        console.log('APP: start starting router');
    });*/

    /*************************************
        global events
    **************************************/
    App.vent.on("metrics:identify", function (distinct_id) {
        var method = 'identify';
        if (Metrics[method]) {
            Metrics[method](distinct_id);
        }
        else {
            console.log("metrics method not found: " + method);
        }
    });
    App.vent.on("metrics:register", function (object) {
        var method = 'register';

        object = object || null;

        if (Metrics[method]) {
            // console.log("Tracking via " + method + " " + JSON.stringify(object));
            Metrics[method](object);
        }
        else {
            console.log("metrics method not found: " + method);
        }
    });
    App.vent.on("metrics:track", function (key, value) {
        var method = 'track';
        value = value || null;

        if (Metrics[method]) {
            Metrics[method](key, value);
        }
        else {
            console.warn("metrics method not found: " + method);
        }
    });

    App.vent.on('critical', function (key, value) {
        trackJs.track('Critical: ' + key + ':' + value);
        var $error = $('#critical-error');
        if (key === 'clear') {
            $error.remove();
        } else {
            if ($error.length != 0) {
                $error.html(value);
            } else {
                $('#content').prepend('<div class="alert alert-danger" id="critical-error" style="' +
                    'text-align: center;' +
                    '">' + value + '</div>');
            }
        }
    });

    // The main initializing function sets up the basic layout and its regions.
    App.initAppLayout = function () {
        var AppLayout = Marionette.LayoutView.extend({
            template: 'layouts/default',
            regions: {
                regionHeader: 'header',     // Contains the header.
                regionTopbar: '#topbar',    // Will contain any user controls (new chat etc. and notifications).
                regionIntro: '#intro',
                regionContent: '#content',  // Will contain the page content.
                regionManageTeam: '#manage-team',  // Will contain pay for other page
                regionToPro: '#upgrade-to-pro-content',  // Will contain pay for yourself page
                regionIntegrations: '#integrations-content',
                regionInvites: '#invites-content',  //will contain invites
                regionContacts: '#contacts-content',  // Will contain the Manager page content.
                regionManage: '#manage-content',  // Will contain the Manager page content.
                regionSupport: '#support-content',  // Will contain the Settings page content.
                regionSettings: '#settings-content',  // Will contain the Settings page content.
                regionFooter: 'footer'      // Will contain the footer.
            }
        });

        App.RegionHeaderLayout = Marionette.LayoutView.extend({
            template: 'ui/header',
            className: 'top-menu-container container',
            regions: {
                menuRegion: '.main-menu'
            },
            events: {
            "click .navbar-brand": "handle_reload"
            },
            handle_reload:function(e){
                window.location.replace(window.location.origin + "/chats/");
            }
        });

        // Inject the main layout into the #main region of the page.
        App.layout = new AppLayout();
        App.regionMain.show(App.layout);
        App.layout.regionHeader.show(new App.RegionHeaderLayout());

        // show a message for all non-supported browsers
        if (detectBrowser().indexOf('chrome') !== -1 || detectBrowser().indexOf('msie 9') !== -1 ||
            detectBrowser().indexOf('msie 10') !== -1 || detectBrowser().indexOf('msie 11') !== -1 ||
            detectBrowser().indexOf('firefox') !== -1) {

        } else{
            var div = $('<div/>').addClass('alert alert-info')
                .css({'zIndex': 1002, 'position': 'relative', 'top': 0, 'width': '100%', 'margin': 0, 'height': 'auto', 'min-height': 'initial'})
                .html('For optimal experience, please use <a href="https://www.google.com/intl/en/chrome/browser/" target="_blank">Chrome</a>. Voxer for Web might not function properly with your current browser.');
            $('body main').css({}).prepend(div);
        }
    };

    // override Marionette's Module for now
    App.module = function (props) {
        return _.extend({ Views: {}, Layouts: {} }, props);
    };

    /* =========================================================================
     * The following will make Marionette's template retrieval work with
     * in both development (templates found in html files) and production
     * environment (templates all compiled as JST templates into the require.js
     * file. This will also use JST instead of the Marionette.TemplateCache.
    */
    Marionette.Renderer.render = function (templateId, data) {
        var path = 'app/templates/' + templateId + '.html';

        // Localize or create a new JavaScript Template object.
        var JST = window.JST = window.JST || {};

        if (typeof templateId === 'function') {
            JST[templateId] = templateId;
            return JST[templateId](data);
        }
        // Make a blocking ajax call (does not reduce performance in production,
        // because templates will be contained by the require.js file).
        if (!JST[path]) {
            $.ajax({
                url: App.root + path  + '?bust=' + voxer.version,
                async: false
            }).then(function (templateHtml) {
                JST[path] = _.template(templateHtml);
            }).fail(function (err) {
                console.log('Failed to load file: ' + path);
            });
        }

        if (!JST[path]) {
            var msg = 'Could not find "' + templateId + '"';
            var error = new Error(msg);
            error.name = 'NoTemplateError';
            throw error;
        }

        // Call the template function with the data.
        return JST[path](data);
    };
    /* ======================================================================== */

    // Returns the app object to be available to other modules through require.js.
    return App;
});

