/**
 *
 */
define('modules/voxer_audio',[
    "app",
    'main/setup',
    "backbone",
    "binaryjs",
    "./voxer_audio_player"
],
    function (App, Setup, Backbone, binaryjs, VoxerAudioPlayer) {
        var VoxerAudio = {
            isReady: false,
            analyser: null,
            socket_connected: false,
            socket_error: false,
            client: null,
            bStream: true,
            audioContext: null,
            outAudioContext: null,
            stream: null,
            recorder: null,
            recording: false,
            sampleRate: null,
            callbacks: {},
            voxer_audio: true,
            sending_audio_chunks: [],
            sent_audio_bytes: 0,
            //Not actually averageLevel but used to keep parity with flash.js in checking whether it works
            micAverageLevel: -1,
            live_audio_playing: {}
        };

        App.VoxerAudio = VoxerAudio;

        App.VoxerAudio.encoder = new Worker('/assets/js/encoder.js');
        App.VoxerAudio.decoder = new Worker('/assets/js/decoder.js');

        VoxerAudio.Init = function (webserver) {
            this.deferred = new $.Deferred();



            VoxerAudio.connect_ws(webserver);

            if (this.get_is_muted()) {
                return this.deferred.reject();
            }

            // Bug
            // Live audio crashes without any error and freezes the sound on loop as an broken windows
            // Having an dedicated audio context helps
            this.outAudioContext = new window.AudioContext();
            this.outSource = App.VoxerAudio.outAudioContext.createScriptProcessor(4096, 1, 1);

            VoxerAudio.bootstrapAudio(function() {
                VoxerAudio.deferred.resolve();
            });

            App.vent.on('logged_out', function () {
                if (VoxerAudio.recording)
                    VoxerAudio.stop_recording();
            });

            return this.deferred.promise();
        };

        VoxerAudio.bootstrapAudio = function (callback) {
            navigator.getUserMedia  = navigator.getUserMedia || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia;

            var session = {
                audio: true
            },
            callback = callback;

            console.log("FLASH: bootstrapAudio");

            // Before bootstraping lets teardown
            // TEARDOWN start
            if (VoxerAudio.recorder) {
                VoxerAudio.recorder.disconnect();
                delete VoxerAudio.recorder;
            }
            if (VoxerAudio.recorder) {
                VoxerAudio.audioInput.disconnect();
                delete VoxerAudio.audioInput;
            }
            if (VoxerAudio.audioContext) {
                VoxerAudio.audioContext.close();
                delete VoxerAudio.audioContext;
            }
            delete VoxerAudio.stream;
            VoxerAudio.callbacks = {};
            // TEARDOWN end



            navigator.getUserMedia(session, function(stream) {
                VoxerAudio.stream = stream;
                VoxerAudio.audioContext = new window.AudioContext();
                VoxerAudio.sampleRate = VoxerAudio.audioContext.sampleRate;
                VoxerAudio.audioInput = VoxerAudio.audioContext.createMediaStreamSource(VoxerAudio.stream);

                // The current version of Chrome(31) requires an valid bufferSize to be provided:
                // https://developer.mozilla.org/en-US/docs/Web/API/AudioContext/createScriptProcessor#Parameters
                var bufferSize = (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) ? 16384 : null,
                    deferred = $.Deferred();


                VoxerAudio.buffer_has_value_deferred = deferred;
                VoxerAudio.recorder = VoxerAudio.audioContext.createScriptProcessor(bufferSize, 1, 1);

                VoxerAudio.recorder.onaudioprocess = (function (VoxerAudio) {
                    return function (e) {
                        try {
                            if (VoxerAudio.check_mic == 1) {
                                VoxerAudio.check_mic += 1;

                                return;
                            }

                            if (VoxerAudio.check_mic == 2) {
                                var channelData = e.inputBuffer.getChannelData(0),
                                    _buffer = parseFloat(channelData.join ? channelData.join('') : channelData.reduce(function(a, b) {
                                        return a + b;
                                    })),
                                    buffer_has_value = _buffer !== 0;

                                if (buffer_has_value) {
                                    VoxerAudio.micAverageLevel = 100;

                                    if (VoxerAudio.buffer_has_value_deferred) {
                                        VoxerAudio.buffer_has_value_deferred.resolve();
                                    }
                                } else {
                                    VoxerAudio.micAverageLevel = -1;
                                    if (VoxerAudio.buffer_has_value_deferred) {
                                        VoxerAudio.buffer_has_value_deferred.reject();
                                    }
                                }

                                App.vent.trigger('mic_level');

                                if (VoxerAudio.start_sending_deferred) {
                                    if (buffer_has_value) {
                                        VoxerAudio.start_sending_deferred.resolve();
                                        VoxerAudio.pause_all();
                                    } else {
                                        VoxerAudio.start_sending_deferred.reject();
                                    }
                                    delete VoxerAudio.start_sending_deferred;
                                }

                                if (buffer_has_value != VoxerAudio.buffer_has_value) {
                                    // Just in case users hit this issue anyway, would be good to have the logs
                                    console.log("\n VoxerAudio.buffer_has_value = " + _buffer + "\n");

                                    App.vent.trigger('metrics:registeer', {
                                        user_has_audio: VoxerAudio.buffer_has_value
                                    });
                                }
                                VoxerAudio.buffer_has_value = buffer_has_value;
                            }
                        } catch (err) {
                            // This is expected to fail alot and retray
                            // so le'ts log it as regular log to don't polute tracker.js
                            console.log(err);
                        }

                        VoxerAudio.onAudio(e);
                    }
                })(VoxerAudio);

                // Connect and disconect the recoreder to the audio in order to check if buffer has value
                VoxerAudio.audioInput.connect(VoxerAudio.recorder);
                VoxerAudio.recorder.connect(VoxerAudio.audioContext.destination);
                VoxerAudio.check_mic = 1;
                deferred.done(function () {
                    VoxerAudio.check_mic = 0;
                    VoxerAudio.recorder.disconnect();
                    VoxerAudio.audioInput.disconnect();
                }.bind(this)).fail(function () {
                    setTimeout(VoxerAudio.bootstrapAudio(callback), 250);
                }.bind(this)).always(function () {
                    delete VoxerAudio.buffer_has_value_deferred;
                }.bind(this));

                function flash_on_audio_callback(audio) {
                    VoxerAudio.encoder.postMessage({
                        cmd: 'encode_buffer',
                        buffer: audio
                    });
                }

                if (VoxerAudio.callbacks['audio'])
                    VoxerAudio.callbacks['audio'].forEach(function(callback) {
                        if (callback == flash_on_audio_callback)
                            VoxerAudio.callbacks.audio.pop(flash_on_audio_callback)
                    });

                VoxerAudio.on('audio', flash_on_audio_callback);

                VoxerAudio.encoder.removeEventListener('message', VoxerAudio.on_encoder_message);
                VoxerAudio.encoder.addEventListener('message', VoxerAudio.on_encoder_message);

            },function(e){
                VoxerAudio.micAverageLevel = -1;
                callback(e);
            });
        };

        VoxerAudio.on_encoder_message = function (e) {
            var is_long_enough = e.data.buffer && e.data.buffer.byteLength && (e.data.buffer.byteLength > 10);
            if (e.data.cmd === 'encoded_buffer' && is_long_enough) {
                VoxerAudio.sent_audio_bytes += e.data.buffer.byteLength;
                VoxerAudio.sending_audio_chunks.push(e.data.buffer);

                VoxerAudio.bStream.write(e.data.buffer);
            }
        };

        VoxerAudio.updateSession = function () {

        };

        VoxerAudio.hide_settings = function () {

        };

        VoxerAudio.show_settings = function () {

        };

        VoxerAudio.get_mic_list = function () {

        };

        VoxerAudio.on = function (event, callback) {
            if (VoxerAudio.callbacks[event] !== undefined) {
                VoxerAudio.callbacks[event].push(callback);
            } else {
                VoxerAudio.callbacks[event] = [callback];
            }
        };

        // Do i have the capability to even do flashless audio?
        VoxerAudio.get_is_muted = function () {
            navigator.getUserMedia  = navigator.getUserMedia || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia;

            if (navigator.getUserMedia === undefined || window.AudioContext === undefined) {
               App.VoxerAudio.isReady = true;
                return true;
            } else {
                App.VoxerAudio.isReady = true;
                return false;
            }
        };

        VoxerAudio.has_mics = function () {
            return true;
        };

        VoxerAudio.connect_ws = function (webserver, callback) {
            var self = this;
            callback = callback || function () {}
            if (this.socket_connected) {
                callback()
                return
            } else if (this.socket_prom) {
                this.socket_prom.then(function(){
                    callback()
                })
                return
            }
            this.socket_prom = new Promise((resolve, reject) => {
                try {
                    this.client = new BinaryClient('wss://' + webserver);
                } catch (e) {
                    return;
                }

                this.client.on('open', function(e) {
                    self.socket_connected = true;
                    VoxerAudioPlayer.set_client(self.client);
                    // console.log("VoxerAudio: BinaryClient connected");

                    if (callback) {
                        resolve();
                        callback();
                    }
                });


                this.client.on('close', function(e) {
                    console.log("VoxerAudio: BinaryClient disconnected");
                    self.socket_connected = false;
                    self.socket_prom = null;
                    // we used to automatically reconnect here but everywhere that needs to use the socket can reconnect
                    // this way we dont have open sockets for no reason
                });
            })

        };

        VoxerAudio.checkMic = function () {
            //check for FLASH mic access after timeline is loaded
            if (this.micAverageLevel === -1) {
                return false;
            }
            return true;
        };

        VoxerAudio.start_recording = function (params, callback) {
            console.log("FLASH: Started recording");
            this.encoder.postMessage({cmd: 'start_encoding'});
            this.sending_audio_chunks = [];
            this.sent_audio_bytes = 0;
            callback;

            var deferred = $.Deferred(),
                that = this;

            if (VoxerAudio.socket_connected && VoxerAudio.recording === false) {
                VoxerAudio.recording = true;
                VoxerAudio.check_mic = 1;
                params.secure_router = {
                    host: App.Auth.attributes.home_router.address,
                    port: App.Auth.attributes.home_router.port
                }
                params.content_type = 'audio';
                params.encoded = true;

                VoxerAudio.start_sending_deferred = deferred;
                VoxerAudio.audioInput.connect(VoxerAudio.recorder);
                VoxerAudio.recorder.connect(VoxerAudio.audioContext.destination);

                deferred.done(function () {
                    console.log("FLASH: Started data sending");
                    VoxerAudio.check_mic = 0;
                    VoxerAudio.bStream = VoxerAudio.client.createStream(params);
                    VoxerAudio.bStream.on('data', function (data) {
                        var data = (typeof data == 'string') ? JSON.parse(data) : data;
                        callback(data);

                        if (data && data.statusCode === 500) {
                            var cookies = $.cookie();
                            for(var cookie in cookies) {
                               $.removeCookie(cookie);
                            }

                        }
                    });

                    VoxerAudio.bStream.on('close', function (data) {
                        var data = (typeof data == 'string') ? JSON.parse(data) : data;

                        callback(data);
                    });
                }.bind(this)).fail(function () {
                    console.error("FLASH: Error sending data");
                });
            } else {
                console.warn("FLASH: No socket_connected");
                var _callback = function (param) {
                    return function () {
                        App.VoxerAudio.start_recording(param, callback).done(function () {
                            deferred.resolve();
                        }.bind(this));
                    }
                }(params);
                VoxerAudio.connect_ws(App.webserver, _callback);
            }

            return deferred;
        };

        VoxerAudio.stop_recording = function () {
            console.log("FLASH: Stopped recording");

            var deferred = $.Deferred(),
                checks = 0;
            if (VoxerAudio.socket_connected) {
                window.setTimeout(function () {
                    VoxerAudio.encoder.postMessage({cmd: 'done_encoding'});
                    VoxerAudio.recorder.disconnect();
                    VoxerAudio.audioInput.disconnect();
                    if (VoxerAudio.bStream && VoxerAudio.bStream.writable) {
                        VoxerAudio.bStream.pause();
                        VoxerAudio.bStream.end();
                    }
                    VoxerAudio.recording = false;


                    // Full blob construction here:
                    var buffer_position = 0,
                        faild_audio = new Uint8Array(VoxerAudio.sent_audio_bytes);

                    VoxerAudio.sending_audio_chunks = VoxerAudio.sending_audio_chunks.map(function(chunk) {
                        return new Uint8Array(chunk);
                    });
                    VoxerAudio.sending_audio_chunks.forEach(function (chunk) {
                        faild_audio.set(chunk, buffer_position);
                        buffer_position += chunk.byteLength;
                    });
                    VoxerAudio.sending_audio_chunks = [];

                    deferred.resolve(URL.createObjectURL(new Blob([faild_audio], {
                        autoRevoke: false,
                        type: 'application/octet-binary'
                    })), buffer_position);
                }, 500);
            } else {
                var callback = function () {
                    return function () {
                        App.VoxerAudio.stop_recording().done(function (blobUrl) {
                            deferred.resolve(blobUrl);
                        });
                    }
                };
                VoxerAudio.connect_ws(App.webserver, callback);
            }

            return deferred.promise();
        };

        VoxerAudio.onAudio = function (e) {
            if(!VoxerAudio.bStream || !VoxerAudio.bStream.writable)
                return;

            var left = e.inputBuffer.getChannelData(0);

            var downsampled = VoxerAudio.downsampleBuffer(left, 16000);
            var callbacks = (VoxerAudio.callbacks['audio']) ? VoxerAudio.callbacks['audio'] : [];
            for (var x = 0; x < callbacks.length; x++) {
                callbacks[x](downsampled);
            }
        }

        VoxerAudio.downsampleBuffer = function (buffer, rate) {
            var sampleRate = VoxerAudio.sampleRate;
            if (rate == sampleRate) {
                return buffer;
            }
            if (rate > sampleRate) {
                throw "downsampling rate show be smaller than original sample rate";
            }
            var sampleRateRatio = sampleRate / rate;
            var newLength = Math.round(buffer.length / sampleRateRatio);
            var result = new Int16Array(newLength);
            var offsetResult = 0;
            var offsetBuffer = 0;
            while (offsetResult < result.length) {
                var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
                var accum = 0, count = 0;
                for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
                    accum += buffer[i];
                    count++;
                }

                result[offsetResult] = Math.min(1, accum / count)*0x7FFF;
                offsetResult++;
                offsetBuffer = nextOffsetBuffer;
            }
            return result.buffer;
        }

        VoxerAudio.live_audio_playing = VoxerAudioPlayer.live_audio_playing;
        VoxerAudio.get_live_audio = VoxerAudioPlayer.get_live_audio;

        return VoxerAudio;
    });

